import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import fastdom from 'fastdom';
import { useSelector } from 'react-redux';

import isMobile from 'utils/isMobile';
import * as authSelectors from 'state/auth/selectors';

import LandingSidebar from 'containers/Landing/Sidebar';
import Ad from 'components/Ad';

import LastThreads from './LastThreads';
import UsersOnline from './UsersOnline';
import SuggestionUser from './SuggestionUsers';

const Sticky = styled.div`
  position: sticky;
  top: ${props => props.top}px;
  margin-left: 16px;
  min-height: calc(100vh - 64px);
  padding-bottom: 32px;
  box-sizing: border-box;
`;
Sticky.displayName = 'Sticky';

const Sidebar = () => {
  const [top, setTop] = useState(80);
  const element = useRef(null);
  const fixPositionTimer = useRef(null);

  const userIsLoggedIn = useSelector(authSelectors.loggedIn);

  useEffect(() => {
    const stickyElement = element.current;
    const timer = fixPositionTimer.current;

    const calcTop = () => {
      fastdom.measure(() => {
        const boh = document.body.offsetHeight;
        const soh = stickyElement.offsetHeight;
        setTop(boh - soh);
      });
    };

    if (stickyElement) {
      calcTop();
      fixPositionTimer.current = setTimeout(calcTop, 5000);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  if (isMobile) return null;

  return (
    <div>
      <Sticky top={top} ref={element}>
        <Ad id="Sidebar Top" />
        {userIsLoggedIn
          ? (
            <>
              <SuggestionUser />
              <LastThreads />
              <Ad id="Sidebar Middle" />
              <UsersOnline />
            </>
          ) : (
            <LandingSidebar landing={false} showMiddleAd />
          )
        }
        <Ad id="Sidebar Bottom" />
      </Sticky>
    </div>
  );
};

Sidebar.propTypes = {
};

Sidebar.defaultProps = {
};

export default Sidebar;
