import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from 'utils/css/colors';

const ButtonWrapper = styled.div`
  display: flex;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.12);
  border-radius: 40px;
  margin: 8px;
  position: relative;
  overflow: hidden;
`;
ButtonWrapper.displayName = 'ButtonWrapper';

const Background = styled.div`
  position: absolute;
  background-color: ${colors.red};
  top: 0;
  height: 100%;
  z-index: 1;
  transition: all 250ms ease-out;

  ${(props) => {
    const width = 100 / props.total;

    return `
      width: ${width}%;
      left: ${width * props.selectedIndex}%;
    `;
  }}
`;
Background.displayName = 'Background';

const ToggleButton = styled.button.attrs({
  type: 'button',
})`
  flex: 1;
  font-size: 16px;
  color: ${props => (props.isSelected ? 'white' : colors.grey)};
  z-index: 10;
  background: transparent;
  border: 0;
  height: 32px;
  cursor: pointer;

  &:active, &:focus {
    outline: none;
  }
`;
ToggleButton.displayName = 'ToggleButton';

const ToggleButtonGroup = ({ buttons, selected, onChange }) => {
  const onClickButton = key => () => {
    onChange(key);
  };

  const selectedIndex = buttons.findIndex(b => b.key === selected);

  return (
    <ButtonWrapper>
      {buttons.map(({ key, label }) => (
        <ToggleButton
          key={`toggle-button-${key}`}
          onClick={onClickButton(key)}
          isSelected={key === selected}
        >
          {label}
        </ToggleButton>
      ))}
      <Background total={buttons.length} selectedIndex={selectedIndex} />
    </ButtonWrapper>
  );
};

ToggleButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selected: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

ToggleButtonGroup.defaultProps = {
  selected: null,
};

export default ToggleButtonGroup;
