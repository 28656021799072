import { Box, Heading, HStack } from '@chakra-ui/react';

interface Props extends React.PropsWithChildren {
  title: string;
}

const PageHeader: React.FC<Props> = ({ title, children }) => {
  return (
    <HStack justifyContent="space-between" alignItems="center" mb={6} zIndex={10} position="relative">
      <Heading variant="primary" as="h1" size="lg" m={0}>{title}</Heading>
      <Box>
        {children}
      </Box>
    </HStack>
  );
};

export default PageHeader;
