import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, HStack, Spinner, Stack, Text,
} from '@chakra-ui/react';

import Api from 'state/api';
import { useTranslation } from 'hooks';
import * as userSelectors from 'state/users/selectors';

import SpinnerWrapper from 'components/Spinner/Wrapper';
import EmptyState from 'components/EmptyState';

import ChecklistWrapper from './Wrapper';
import NoAccess from './NoAccess';
import locales from './i18n';

const Checklist: React.FC = () => {
  const { t } = useTranslation(locales);
  const params: any = useParams();

  const userId = useSelector(userSelectors.getByUsername(params.username));
  const pronoun = useSelector(userSelectors.getPronoun(userId));

  const [checklist, setChecklist] = useState<any[] | null>(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await Api.req!.get(`/users/${params.username}/checklist`);
        setChecklist(data);
      } catch (e: any) {
        setError(e?.response?.status);
      }
    };

    fetch();
  }, [params.username]);

  if (error === 403) return <NoAccess />;
  if (error) return <ChecklistWrapper><EmptyState title={t('You can\'t access this checklist at this moment')} subtitle={t('Try again later')} /></ChecklistWrapper>;
  if (checklist === null) return <SpinnerWrapper><Spinner color="#999" /></SpinnerWrapper>;

  const allItemsCount = (checklist || []).reduce((acc, current) => (
    acc + current.selections.filter((i: any) => !!i.item).length
  ), 0);
  if (allItemsCount === 0) return <EmptyState subtitle={t('This checklist is empty')} />;

  return (
    <Accordion allowToggle defaultIndex={0} mt={6}>
      {checklist?.map((section) => {
        const selections = section.selections.filter((i: any) => !!i.item);
        if (!selections.length) return null;

        return (
          <AccordionItem key={section.category}>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontWeight="semibold">
                  {t(section.category)}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Box fontSize="sm">
                <HStack
                  justifyContent="flex-end"
                  fontWeight="500"
                  color="gray.500"
                  display={{ base: 'none', md: 'flex' }}
                  textTransform="uppercase"
                  mb={1}
                  px={2}
                >
                  <Box w="115px">{t('Experience')}</Box>
                  <Box w="115px">{t('Interest')}</Box>
                </HStack>
                {section.selections.map(({ item, experience, interest }: any, index: number) => (
                  <Stack
                    key={item.id}
                    bgColor={!(index % 2) ? 'gray.100' : 'transparent'}
                    px={2}
                    py={4}
                    direction={{ base: 'column', md: 'row' }}
                    justifyContent={{ base: 'initial', md: 'space-between' }}
                    alignItems={{ base: 'stretch', md: 'center' }}
                  >
                    <Box>{t(item.name, { context: pronoun })}</Box>
                    <Stack justifyContent="flex-end" color="gray.500" fontSize="xs" direction={{ base: 'column', md: 'row' }}>
                      <HStack justifyContent="space-between">
                        <Text display={{ base: 'block', md: 'none' }}>
                          {t('Experience')}
                          :
                        </Text>
                        <Text w="115px" color="black" fontSize="14px">{t(`EXPERIENCE.${experience}`)}</Text>
                      </HStack>
                      <HStack justifyContent="space-between">
                        <Text display={{ base: 'block', md: 'none' }}>
                          {t('Interest')}
                          :
                        </Text>
                        <Text w="115px" color="black" fontSize="14px">{t(`INTEREST.${interest}`)}</Text>
                      </HStack>
                    </Stack>
                  </Stack>
                ))}
              </Box>
            </AccordionPanel>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

export default Checklist;
